import React from "react"

import Postteaser from "../components/post-teaser"

const PostList = (props) => {
    const { data } = props
    const pages = data.allMarkdownRemark.edges;

    if (props.tags) {
        pages = pages.filter(page => page.node.frontmatter.tags.match(props.tags));
    }

    return (
        <aside className="postList">
            {/*<h1 className="postList__heading">{data.allMarkdownRemark.totalCount > 1 ? `${data.allMarkdownRemark.totalCount} Posts` : `${data.allMarkdownRemark.totalCount} Post`}</h1>*/}
            <ol className="postList__listing">
                {pages.map(({ node }) => (
                    <li className="postList__item" key={node.id}>
                        <Postteaser post={node} />
                    </li>
                ))}
            </ol>
        </aside>
    )
}

export default PostList