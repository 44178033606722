import React from "react"
import { Link } from "gatsby"
import CTA from "./chrome/cta";

const PostTeaser = (props) => {

    const { post } = props;
    const { frontmatter: { tags } } = post

    /* eslint-disable-next-line */
    const Tags = tags.map(tag => <li className="tag" key={tag.toLowerCase().replace(/ /g, '_')}><Link to={`/tags/${tag}`} className="tag__link">{tag}</Link></li>);

    return (
        <article className="postTeaser">
            <header className="postTeaser__header">
                <h1 className="postTeaser__heading">
                    <Link className="postTeaser__link" to={post.fields.slug}>
                        {post.frontmatter.title}
                    </Link>
                </h1>
                {/* Tags && <ul>{Tags}</ul> */}
                <div className="postTeaser__dates">
                    <p className="postTeaser__date">First published: {post.frontmatter.datePublished}</p>
                    <p className="postTeaser__date">Last modified: {post.frontmatter.dateModified}</p>
                </div>
            </header>

            <Link className="postTeaser__link" to={post.fields.slug}>
                <p className="postTeaser__excerpt">{post.excerpt}</p>
            </Link>

            <footer className="postTeaser__footer">
                <CTA
                    to={post.fields.slug}
                    text="Read more"
                />
            </footer>
        </article>
    );
};

export default PostTeaser