import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"

import List from "../layouts/list"
import Main from "../components/main"
import PageHeader from "../components/chrome/pageHeader"
import PageFooter from "../components/chrome/pageFooter"
import Postlist from "../components/post-list"
import Container from "../layouts/container"
import Breadcrumbs from "../components/chrome/breadcrumbs"

// eslint-disable-next-line no-unused-vars
import Styles from '../styles/styles.scss'

const PagePosts = () => {
    const query = useStaticQuery(
        graphql`
            query {
                allMarkdownRemark(
                    filter: {fileAbsolutePath: {regex: "/(content/posts/)/"  }},
                    sort: { fields: [frontmatter___dateModified], order: DESC }
                ) {
                totalCount
                edges {
                    node {
                    id
                    frontmatter {
                        title
                        subtitle
                        date
                        dateModified
                        datePublished
                        tags
                    }
                    fields {
                        slug
                    }
                    excerpt
                    }
                }
                }
            }
        `
    )

    return (
        <List>
            <SEO
                title="Posts"
                article={true}
            />
            <PageHeader headerText="What goes around in my head" />

            <Main>
                <Container>
                    <Breadcrumbs title="Posts" path="/posts" />
                </Container>
                <div className="row">
                    <div className="col--16">
                        <Container>
                            <h1 className="h1">Posts</h1>
                            <p>When I started writing articles, the most obvious platform to post them on was Medium. But I felt that some texts might be too short or too rough to be published there and thus I decided to keep them here on my site.</p>
                            <p>There might be cases where I post something on Medium and link to more details here, though. But times will tell. For now I wish you happy reading!</p>

                            <Postlist data={query} />
                        </Container>
                    </div>
                </div>
            </Main>

            <PageFooter />
        </List>
    )
}

export default PagePosts